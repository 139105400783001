import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';

export default function Tooltip({message, color, bgColor, borderColor, icon, className, showOverride, left, right, large, badge}) {
  const [show, setShow] = useState(false);
  return (
    <div onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)} className={`inline-block ml-1 relative ${large ? 'w-4' : 'w-2'} ${className}`}>
      <div className={`absolute ${large ? '-bottom-1.5' : 'bottom-0'} text-left`}>
        {(showOverride || show) && message && <div className="relative -left-12 flex w-1/4screen z-30">
          <div className={` text-xxs w-fit flex p-2 rounded  grow-0 bg-white ${(borderColor || color) ? `border-${borderColor || color}` : 'border-blue'} border ${right ? 'relative left-10' : ''} ${left ? 'relative -left-28' : ''}`}>
            <p className="grow min-w-16" dangerouslySetInnerHTML={{ __html: message }}>
            </p>
          </div>
          <div className={`absolute rotate-45 left-12 -bottom-1.5 w-3 h-3 ${(borderColor || color) ? `border-${borderColor || color}` : 'border-blue'} border-r border-b bg-white`}></div>
        </div>}
        <FontAwesomeIcon className={`${color ? `text-${color}` : 'text-blue'} ${large ? '' : 'text-xxs'} ${badge ? `rounded-full bg-${bgColor} w-3 h-3 p-0.5` : ''} top-0 ${message ? 'cursor-pointer' : ''}`} icon={icon || faCircleQuestion}/>
      </div>
    </div>
  )
}
