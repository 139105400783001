import React, {useState, useRef, useEffect, useContext, useMemo} from 'react';
import Input from 'components/inputs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faBuilding } from '@fortawesome/free-solid-svg-icons';
import AddTrustAccount from './AddTrustAccount';
import { useQuery, useMutation, gql } from "@apollo/client";
import TrustAccount from './TrustAccount';
import SurveyContext from 'contexts/SurveyContext';
import Tooltip from 'components/common/Tooltip';
import {getSurveyValue, pathMaker, getCalculatedValue} from 'selectors/formSelectors';
import { typeToLabel } from 'helpers/general';
import ButtonPrimary from 'components/common/Button';
import ParentContext from 'contexts/ParentContext';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import { Link } from 'react-router-dom';
import Loading from 'components/common/Loading';
import ConfirmNotice from 'components/notices/ConfirmNotice';

const ADD_TRUST_ACCOUNT_ITEMS = gql`
  mutation AddTrustAccount(
    $trustAccountItems: [trustAccountItemInput],
    $trustAccountId: String!,
    $firmId: String,
    $fileId: String,
    $surveyId: String,
  ) {
    createTrustAccountItems(
      trustAccountItems: $trustAccountItems,
      trustAccountId: $trustAccountId,
      firmId: $firmId,
      fileId: $fileId,
      surveyId: $surveyId,
    ) {
      id
    }
  }
`;

export default function TrustAccountLineItemsInput({hideLabel, label, description, questionKey, showTrustCalculation, hideTrustCalculation, date, mapping, questions, children}) {
  const [search, setSearch] = useState();
  const { file, updateAnswers, updateErrors } = useContext(SurveyContext);
  const { row, prevRows, parentPath } = useContext(ParentContext);
  const [showConfirm, setShowConfirm] = useState();
  const [addTrustAccountItemsMutation, { loading }] = useMutation(ADD_TRUST_ACCOUNT_ITEMS);  

  const path = useMemo(() => {
      return pathMaker(questionKey, parentPath, row);
  }, [questionKey, parentPath, row]);

  const hide = useMemo(() => {
    if (hideTrustCalculation || showTrustCalculation) {
      let res = hideTrustCalculation ?  getCalculatedValue(file, hideTrustCalculation, prevRows) : !getCalculatedValue(file, showTrustCalculation, prevRows);
      return res ? true : false;
    } else {
      return false;
    }
  },[file, hideTrustCalculation, showTrustCalculation, prevRows]);

  const surveyValue = useMemo(() => {
    return file && getSurveyValue(file.answers, path);
  }, [file, path]);

  const addToTrustAccount = () => {
    let calculatedDate = (date && file && file.answers && getCalculatedValue(file, date, prevRows)) || new Date();
    let trustAccountItems = mapping && file && file.answers && getCalculatedValue({...file, questions}, mapping, prevRows, null, true).map(item => ({date: calculatedDate, ...item, value: item.value ? item.value.toString() : undefined}));
    addTrustAccountItemsMutation({variables: {
      trustAccountItems,
      trustAccountId: file.answers.trustAccount.id,
      firmId: file.firmId,
      fileId: file.id,
      surveyId: file.surveyId,
    }})
    .then(res => {
      updateAnswers(path, true);
    })
  }

  return (
    <>
      {showConfirm && <ConfirmNotice
        title={`Caution`}
        message={<div>
          Hitting <strong>UPDATE</strong> will <strong>OVERRIDE CHEQUES</strong> in the trust account page and <strong>ADD A NEW LINE ITEM</strong>  for cheques that were not previously there. 
          <br></br> 
          Once cheques are issued, we recommend you make changes directly in the trust account page for this file to ensure accuracy.
        </div>}
        onConfirm={addToTrustAccount}
        onClose={() => setShowConfirm(false)}
      /> }
      <div className="relative self-end flex -mt-11 h-9">
        {
          loading ?
            <div className="text-sm text-green self-end flex justify-center items-center pr-4">
              <Loading/>
            </div>
          :
            surveyValue ?
            <div className="text-sm text-green self-end flex justify-center items-center">
              <Link to={`/files/${file.surveyId}/${file.id}/trust-account`}>
                <FontAwesomeIcon icon={faCircleCheck}/> Added to Trust Account
              </Link>
              |
              <p onClick={() => setShowConfirm(true)} className="underline hover:font-bold cursor-pointer">Update</p>
            </div>
            :
            (hide || <ButtonPrimary onClick={addToTrustAccount} disabled={hide}>Add To Trust Account</ButtonPrimary>)
        }
      </div>
      {children}
    </>
  )
}
