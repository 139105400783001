import { faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Message from 'components/common/Message';
import React, {useState, useEffect, useMemo, useRef} from 'react';

export default function Input({type, valueOverride, onChange, onBlur, strictBlur, readOnly, placeholder, positive, className, clear, error, forwardRef, pattern, patternErrorMessage, rows, auto, linked,...rest}) {
  const [value, setValue] = useState(valueOverride || '');
  const [patternError, setPatternError] = useState("");
  const [cursor, setCursor] = useState(null);
  const ref = useRef(null);
  
  const autoGrow = (element) => {
    element.style.height = "5px";
    element.style.height = (element.scrollHeight) + "px";
  }

  useEffect(() => {
     const input = ref.current;
     if (input && (type === 'number')) input.setSelectionRange(cursor, cursor);
     if (type === 'textarea' && auto && ref.current) {
       autoGrow(ref.current);
     }
  }, [ref, cursor, value]);

  useEffect(() => {
    if ((valueOverride || valueOverride == 0) || (valueOverride === '' && value)) {
      if (type === "number") {
        if (readOnly) {
          setValue(Number(valueOverride)?.toFixed(2).toString()?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"));
        } else {
          setValue(valueOverride?.toString()?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"));
        }
      } else {
        setValue(valueOverride?.replace?.('\\u8217  ', "'"));
      }
    }
  }, [valueOverride]);

  useEffect(() => {

    if (clear) {
      setValue('');
    }
  }, [clear]);

  useEffect(() => {
    if (pattern) {
      const regex = new RegExp(pattern);
      let passed = regex.test(value);
      if (value && (!passed || error)) {
        setPatternError(passed ? error : (patternErrorMessage || "Invalid Format"));
      } else {
        setPatternError(null);
      }
    }
  }, [pattern, error, value, patternErrorMessage])

  const changeValue = (e) => {
    let newValue = e.target.value;
    if (type === "number" && newValue) {
      newValue = newValue[0]?.replace(/[^\d-]/g, '') + newValue?.substring(1).replace(/[^\d.]/g, '');
      newValue =  newValue.split('.').length > 2 ? newValue.replace(".", "") : newValue;
      if (positive && newValue < 0) newValue = 0;
      let newDisplay = newValue?.toString()?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
      setValue(newDisplay);
      if (value.length - newDisplay.length > 1) {
        setCursor(1);
      } else {
        setCursor(e.target.selectionStart + (newDisplay.length - value.length + (Math.sign(newDisplay.length - value.length)*-1)));
      }
    } else {
      if (positive && newValue < 0) newValue = 0;
      setValue(newValue);
    }
    if (value === '' && !valueOverride && !strictBlur) { //Math.abs(newValue.length - value.length) > 1 || 
      handleOnBlur(null, newValue);
    }
    onChange && onChange(newValue);
  }

  const handleOnBlur = (event, override = null) => {
    let newVal = override || value
    if (onBlur) {
      if (type === "number" && newVal) {
        newVal = newVal[0]?.replace(/[^\d-]/g, '') + newVal?.substring(1).replace(/[^\d.]/g, '');
        newVal =  newVal.split('.').length > 2 ? newVal.replace(".", "") : newVal;
      }
      onBlur(newVal);
    }
  }

  return (
    <>
      {type === 'textarea' ?
        <textarea 
          placeholder={placeholder} 
          className={`
            resize-none
            ${auto ? 'overflow-hidden' : ''}
            border border-light-grey p-1 rounded shadow-md ${className} 
            ${readOnly && 'pointer-events-none cursor-default'}
            ${error && 'text-red'}
          `} 
          rows={auto ? 1 : rows || 3}
          type={type || "text"} 
          readOnly={readOnly} 
          value={value} 
          onChange={e => changeValue(e)}
          onBlur={handleOnBlur}
          ref={forwardRef || ref}
          pattern={pattern}
          {...rest}
        />
      :
        <input 
          placeholder={placeholder} 
          className={`
            ${linked == 1 ? 'border-2 border-green' : linked == -1 ? 'border-2 border-med-grey' : 'border border-light-grey'} p-1 rounded shadow-md ${className} 
            ${readOnly && 'pointer-events-none cursor-default'}
            ${error && 'text-red'}
          `} 
          type={['text', 'number', '', null].includes(type) ? "text" : type} 
          readOnly={readOnly} 
          value={value} 
          onChange={e => changeValue(e)}
          onBlur={handleOnBlur}
          ref={forwardRef || ref}
          pattern={pattern}
          {...rest}
        />
      }
      {patternError &&
      	<Message negative={true} className="mt-2 text-xs">
          {patternError}
        </Message>
      }
    </>
  )
}
