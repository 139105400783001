import React, {useContext, useEffect, useState, useMemo, useRef, useCallback} from 'react';
import {getSurveyValue, getCalculatedValue, pathMaker, getValueWithIndexes} from 'selectors/formSelectors';
import SurveyContext from 'contexts/SurveyContext';
import Tooltip from 'components/common/Tooltip';
import Input from 'components/inputs/Input';
import _ from "lodash";
import ButtonPrimary from 'components/common/Button';
import SurveyList from './SurveyList';
import { List } from 'components/inputs';
import ParentContext from 'contexts/ParentContext';
import { softSubset } from 'helpers/general';
const SimpleCategorizerInput = ({ 
    label, 
    hideLabel, 
    description,
    item,
    readOnly,
    skipPath, 
    skipParentPath,
    readOnlyCalculation,
    questionKey,
    mapping,
    options,
    dependencies,
}) => {
    const { file, updateAnswers, updateCalculation, lastUpdate } = useContext(SurveyContext);
    const {prevRows, parentPath, subscribeCleanup, row} = useContext(ParentContext);
    const [selectedOption, setSelectedOption] = useState();
    const [depsChanged, setDepsChanged] = useState(0);
    // option
    // {name: 'Disbursement + GST', value: 'accountDisbursements', selected: "", mapping: "{disbursement: {taxable: '0.05', name: answers.additionalCharges[$1].charge.name, amount: answers.additionalCharges[$1].charge.amount}}"}, 
    const fileRef = useRef();
    fileRef.current = file;

    const path = useMemo(() => {
        return pathMaker(skipPath ? '' : questionKey,  skipParentPath ? '' : parentPath, row);
    }, [questionKey, parentPath, row]);

    const fileAnswers = useMemo(() => {
        return file && file.answers;
    }, [file]);

    const readOnlyFlag = useMemo(() => {
        return readOnly || (readOnlyCalculation ? getCalculatedValue(file, readOnlyCalculation, prevRows) : false);
    }, [readOnlyCalculation, file]);

    useEffect(() => {
        if (dependencies) {
            for (let update of lastUpdate) {
              if (dependencies.join('').includes('answers.' + update)) {
                setDepsChanged(depsChanged + 1);
              }
              if (update === 'all' && selectedOption) {
                updateCalculation(path, selectedOption);
              }
            }
        }
      }, [lastUpdate]);

    // check if any options meet the selection calculations
    // check if deps changed
    useEffect(() => {
        for (let option of options) {
            let destination = getSurveyValue(fileAnswers, option.value);
            if (destination?.find(val => softSubset(val, getValueWithIndexes(option.mapping || mapping, prevRows)))) {
                // if so set selected
                setSelectedOption(option);
                updateCalculation(path, option.value);
                return;
            }
        }
        setSelectedOption();
        updateCalculation(path);
    }, [depsChanged]);

    // on file change check if selection should be unselected
    useEffect(() => {
        let destination = selectedOption && getSurveyValue(fileAnswers, selectedOption.value);
        if (destination && !destination.find(val => softSubset(val, getValueWithIndexes(selectedOption.mapping || mapping, prevRows)))) {
            setSelectedOption();
            updateCalculation(path);
        }
    }, [fileAnswers]);

    // on categorizer line item removal, remove destination
    useEffect(() => {
        if (subscribeCleanup) {
            subscribeCleanup(`(${prevRows.at(-1)})${questionKey}`,(removeIndex) => {
                if (!selectedOption) {
                    return
                }
                if (removeIndex === prevRows.at(-1)) {
                    // remove destination
                    setSelected(null, true);
                } else if (removeIndex < prevRows.at(-1)) {
                    let newPrevRows = [...prevRows];
                    newPrevRows.splice(-1, 1, prevRows.at(-1) -1);
                    let newData = getValueWithIndexes(selectedOption.mapping || mapping, newPrevRows);
                    let oldData = getValueWithIndexes(selectedOption.mapping || mapping, prevRows);
                    let destination = getSurveyValue(fileAnswers, selectedOption.value);
                    updateAnswers(selectedOption.value, destination.map(val => softSubset(val, oldData) ? {...val, data: val.data.map((data, i) => ({...data, ...newData.data[i]}))} : val));
                }
            })
            return () => {
                subscribeCleanup(`(${prevRows.at(-1)})${questionKey}`);
            }
        }
    }, [subscribeCleanup, setSelectedOption, prevRows, questionKey, selectedOption, fileAnswers]);

    const setSelected = (option, cleanup) => {
        let newOption = option && options.find(opt => opt.name+opt.value == option);
        // remove old selection
        if (selectedOption && newOption?.value) {
            let paths;
            let data;
            if (selectedOption.value === newOption.value) {
                let destination = getSurveyValue(fileAnswers, newOption.value);
                paths = newOption.value;
                data = [...destination.filter(val => !softSubset(val, getValueWithIndexes(selectedOption.mapping || mapping, prevRows))), getValueWithIndexes(newOption.mapping || mapping, prevRows)]
            } else {
                paths = [selectedOption.value, newOption.value];
                let oldDestination = getSurveyValue(fileAnswers, selectedOption.value);
                let newDestination = getSurveyValue(fileAnswers, newOption.value);
                data = [oldDestination.filter(val => !softSubset(val, getValueWithIndexes(selectedOption.mapping || mapping, prevRows))), [...newDestination, getValueWithIndexes(newOption.mapping || mapping, prevRows)]];
            }
            updateAnswers(paths, data);
        } else if (selectedOption) {
            let oldDestination = getSurveyValue(fileAnswers, selectedOption.value);
            updateAnswers(selectedOption.value, oldDestination.filter(val => !softSubset(val, getValueWithIndexes(selectedOption.mapping || mapping, prevRows))));
        }else if (newOption?.value) {
            let newDestination = getSurveyValue(fileAnswers, newOption.value) || [];
            updateAnswers(newOption.value, [...newDestination, getValueWithIndexes(newOption.mapping || mapping, prevRows)]);
        }
        // add new selection
        if (!cleanup) {
            setSelectedOption(newOption);
            updateCalculation(path, newOption.value);
        }
    }

    return (
        <div className="flex flex-col justify-between input grow-0 items-start">
            {!hideLabel && label && (
                description ? 
                    <label>
                        {label}
                        <Tooltip message={description} left/>
                    </label>
                :
                    <label>
                        {label}
                    </label>
            )}
            <List 
                options={[{name: '', value: ''}, ...options.map(option => ({name: option.name, value: option.name+option.value}))]}  
                valueOverride={(selectedOption?.name + selectedOption?.value) || ''}
                readOnly={readOnlyFlag}
                onChange={setSelected} 
            />
        </div>
    )
};

export default SimpleCategorizerInput;